import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import(/* webpackChunkName: "about" */ '@/views/rule.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('@/views/details.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue')
  },
  {
    path: '*',
    name: 'home',
    component: () => import('@/views/Home.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
