<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" class="img" />
      <p>贵阳市云岩区箐英心霖素养培训学校有限公司</p>
    </div>
    <ul class="navbar">
      <router-link to="/">
        <li class="navbar-item">课程广场</li>
      </router-link>
      <a href="https://www.doit365.net/login" target="_blank">
        <li class="navbar-item">我要发布</li>
      </a>
      <a @click="jump">
        <li class="navbar-item">我要查询</li>
      </a>
      <router-link to="/contact">
        <li class="navbar-item">联系我们</li>
      </router-link>
      <router-link to="/about">
        <li class="navbar-item">关于我们</li>
      </router-link>
    </ul>
    <div class="logoOn">
      <a href="https://www.doit365.net/login" target="_blank">{{ userName }}</a>
      <div class="logo_text">{{ userName === '登录' ? ' / ' : '' }}</div>
      <a href="https://www.doit365.net/register" target="_blank">{{
        userName === '登录' ? '注册' : ''
      }}</a>
    </div>
  </div>
</template>
<script>
  import { getName } from '@/helper/api';
  export default {
    name: 'Header',
    data: () => ({
      userName: '登录',
      url: ''
    }),
    created() {
      getName().then((res) => {
        this.userName = res.data.userName;
        this.url = res.data.url;
        localStorage.setItem('isLogin', this.userName);
        console.log('res', res);
      });
    },
    props: {},
    methods: {
      jump() {
        localStorage.setItem('hash', 'class');
        window.open('/dist/', '_self');
      }
    }
  };
</script>
<style lang="less" scoped>
  p {
    margin: 10px;
  }
  a {
    text-decoration: none;
    color: #000;
    &:hover {
      color: #3370ff;
    }
  }
  ul {
    list-style-type: none;
  }
  .img {
    width: 108px;
    height: 80px;
  }
  .header {
    color: #000;
    display: flex;
    height: 100px;
    align-items: center;
    padding: 10px 100px;
    min-width: 900px;
    background-color: #f1f3f5;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .navbar {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;
    &-item {
      padding: 4px 0px;
      margin: 0 20px;
      &.active {
        border-bottom: 2px solid;
        color: #3370ff;
      }
    }
  }
  .logoOn {
    margin-left: 80px;
    color: #000;
    display: flex;
    .logo_text {
      padding: 0 8px;
    }
  }
</style>
