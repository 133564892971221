import request from '@/helper/request';
// 广场列表
export function getList() {
  return request({
    url: 'course/list',
    method: 'get'
  });
}

// 搜索
export function search(params) {
  return request({
    url: 'course/search',
    method: 'get',
    params
  });
}
//详情
export function getDetails(params) {
  return request({
    url: 'course/detail',
    method: 'get',
    params
  });
}
//点赞
export function goLike(params) {
  return request({
    url: 'course/comment_like',
    method: 'get',
    params
  });
}

//
export function getName() {
  return request({
    url: 'user/return_user_name',
    method: 'get'
  });
}
