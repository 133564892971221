<template>
  <div id="app">
    <header>
      <Header ref="header"></Header>
    </header>
    <router-view />
    <footer>
      <Footer></Footer>
    </footer>
  </div>
</template>
<script>
  import Header from '@/components/header';
  import Footer from '@/components/footer';
  export default {
    components: { Header, Footer }
  };
</script>
<style lang="less">
  html,
  body,
  #app {
    height: 100%;
    overflow-x: clip;
  }
  body {
    margin: 0;
  }
  #app {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
