<template>
  <div class="footer">
    <div class="footer_top">
      <div class="left">
        <div class="left_one">
          <router-link to="/">
            <li>课程广场</li>
          </router-link>
          <router-link to="/about">
            <li>关于我们</li>
          </router-link>
        </div>
        <div>
          <router-link to="/rule">
            <li>《信息发布规则》</li>
          </router-link>
        </div>
      </div>
      <div>
        <p>贵阳市云岩区箐英心霖素养培训学校有限公司</p>
        <p>地址：贵州省贵阳市云岩区文昌阁街道九华路13号中央公园1楼9号</p>
        <p>联系方式：13765812930</p>
      </div>
    </div>
    <div>ICP备案号：黔ICP备2023001544号</div>
  </div>
</template>
<script>
  export default {
    name: 'Footer',
    data: () => ({}),
    created() {},
    props: {},
    methods: {}
  };
</script>
<style lang="less" scoped>
  p {
    margin: 10px;
  }
  a {
    text-decoration: none;
    color: #000;
    &:hover {
      color: #3370ff;
    }
  }
  li {
    list-style-type: none;
    margin: 10px;
  }

  .footer {
    padding: 10px 100px;
    min-width: 900px;

    background-color: #fbfbfb;
    color: #757575;
  }
  .footer_top {
    display: flex;
    justify-content: space-between;
  }
  .left {
    display: flex;
    .left_one {
      margin-right: 100px;
    }
  }
  a {
    color: #757575;
  }
</style>
